.container-center {
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121212;
}

.login {
  background-color: #eaeaec;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-area {
  display: flex;
  justify-content: center;
  background-color: #181c2e;
  width: 100%;
}

.login-area img {
  padding: 20px;
  width: 170px;
  height: 130px;
}

form {
  margin-top: 1.5em;
  width: 90%;
  display: flex;
  flex-direction: column;
}

form h1 {
  text-align: center;
  margin-bottom: 0.5em;
  color: #181c2e;
}

form h1 {
  text-align: center;
}

.login input {
  margin-bottom: 15px;
  height: 40px;
  border: 0;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  background-color: #fff;
}

form button {
  height: 35px;
  border: 0;
  border-radius: 8px;
  background-color: #181c2e;
  color: #fff;
  font-size: 1.3em;
}

.login a {
  margin: 1.5em;
  color: #000;
  cursor: pointer;
}
